import {CustomiseTableColumn} from '@model/i-table.model';
import {ProductConstant} from '@constant/product.constant';
import {GeneralService} from '@modules/shared-lib/common/service/general.service';

export class TableConstant {
    // common
    public static readonly ALL = 'ALL';

    // holding table
    public static readonly PORTFOLIO_HOLDING_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Product Type',
            key: 'productType',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'productType'),
            checked: true,
            nzWidth: '150px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
        },
        {
            label: 'Security Name',
            key: 'productName',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'productName'),
            checked: true,
            nzWidth: '250px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px font-semibold',
            dataType: 'TEXT',
            sortOrder: '',
        },
        {
            label: 'Currency',
            key: 'currencyCode',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'currencyCode'),
            checked: true,
            nzWidth: '100px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            sortOrder: '',
        },
        {
            label: 'Price',
            key: 'currentProductPrice',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'currentProductPrice'),
            checked: true,
            nzWidth: '90px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP3',
            sortOrder: '',
        },
        {
            label: 'Qty',
            key: 'currentUnits',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'currentUnits'),
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP3',
            sortOrder: '',
            hasTooltip: true,
        },
        {
            label: 'Value',
            key: 'value',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'value'),
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            sortOrder: '',
        },
        {
            label: 'Value',
            key: 'valueBase',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'valueBase'),
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            sortOrder: '',
            isBaseCurrency: true,
        },
        {
            label: '% Total Exposure',
            key: 'currentNavPercent',
            checked: true,
            nzWidth: '100px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'PERCENT4DP',
            hasTooltip: true,
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'currentNavPercent'),
        }
    ];

    // trade table
    public static readonly PORTFOLIO_TRADE_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Action',
            key: 'action',
            checked: true,
            nzWidth: '100px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
            nzLeft: true,
        },
        {
            label: 'Name',
            key: 'productName',
            checked: true,
            nzWidth: '200px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px font-semibold',
            dataType: 'TEXT',
            nzLeft: true,
        },
        {
            label: 'Product Type',
            key: 'productType',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
            nzLeft: true,
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'productType'),
        },
        {
            label: 'Order Metrics',
            key: 'ordMetrics',
            checked: true,
            nzWidth: '160px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'ordMetrics'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_ETF, ProductConstant.PRODUCT_TYPE_UT]
        },
        {
            label: 'Order Type',
            key: 'orderType',
            checked: true,
            nzWidth: '160px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'orderType'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Time in Force',
            key: 'timeInForce',
            checked: true,
            nzWidth: '100px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'timeInForce'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'GTD',
            key: 'gtd',
            checked: true,
            nzWidth: '180px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Bank Name',
            key: 'bankName',
            checked: true,
            nzWidth: '200px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'bankName'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Effective Date',
            key: 'effectiveDate',
            checked: true,
            nzWidth: '180px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'effectiveDate'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Maturity Date',
            key: 'maturityDate',
            checked: true,
            nzWidth: '180px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'maturityDate'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Currency',
            key: 'currency',
            checked: true,
            nzWidth: '100px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'currency'),
        },
        {
            label: 'Minimum Amount',
            key: 'ordProductMinOrderAmount',
            checked: true,
            nzWidth: '130px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'ordProductMinOrderAmount'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_UT, ProductConstant.PRODUCT_TYPE_ON_DEPO],
        },
        {
            label: 'Est. Price',
            key: 'dailyPrice',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP4',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'dailyPrice'),
        },
        {
            label: 'Price',
            key: 'price',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'price'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Interest Rate',
            key: 'interestRate',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'interestRate'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Effective Date (Transfer Date)',
            key: 'transferDate',
            checked: true,
            nzWidth: '180px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'transferDate'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_ON_DEPO],
        },
        {
            label: 'Quantity',
            key: 'quantity',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'quantity'),
        },
        {
            label: 'Accrued Interest',
            key: 'estimatedAccruedInterest',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'estimatedAccruedInterest'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_BOND],
        },
        {
            label: 'Expected Yield',
            key: 'expectedYield',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'expectedYield'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_BOND],
        },
        {
            label: 'Value',
            key: 'productOrderValue',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'productOrderValue'),
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_ETF, ProductConstant.PRODUCT_TYPE_UT]
        },
        {
            label: 'Value',
            key: 'productOrderValueBase',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            isBaseCurrency: true,
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'productOrderValueBase'),
        },
        {
            label: '% Total Exposure',
            key: 'dailyTotalExposure',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'dailyTotalExposure'),
        },
        {
            label: 'IG Check Status',
            key: 'igCheckStatus',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'igCheckStatus'),
            checked: true,
            nzWidth: '110px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
        },
    ];

    // fund summary
    public static readonly PORTFOLIO_SUMMARY_TABLE_TITLE = 'portfolio_summary';
    public static readonly PORTFOLIO_SUMMARY_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Fund',
            key: 'fundName',
            showSort: true,
            checked: true,
            nzWidth: '230px',
            nzClass: 'tHeadFont-14',
            sortOrder: '',
            nzAlign: 'left',
            nzClassBody: 'font-semibold text-13px break-words',
            dataType: 'TEXT',
        },
        // {
        //     label: 'AUM',
        //     key: 'fundCcyAum',
        //     showSort: true,
        //     checked: true,
        //     nzWidth: '200px',
        //     nzClass: 'tHeadFont-14',
        //     nzClassBody: 'text-13px',
        //     sortOrder: '',
        //     nzAlign: 'right',
        //     dataType: 'DP2',
        // },
        // {
        //     label: 'Daily AUM Change',
        //     key: 'fundCcyAumChangePercentage',
        //     showSort: true,
        //     checked: true,
        //     nzWidth: '130px',
        //     nzClass: 'tHeadFont-14',
        //     nzClassBody: 'text-13px',
        //     sortOrder: '',
        //     nzAlign: 'right',
        //     dataType: 'PERCENTCOLOR',
        // },
        {
            label: 'NAV Price',
            key: 'currentNavPrice',
            showSort: true,
            checked: true,
            nzWidth: '130px',
            nzClass: 'tHeadFont-14',
            sortOrder: '',
            nzAlign: 'right',
            dataType: 'DP4',
        },
        {
            label: 'Daily NAV Price Change',
            key: 'navChangePercentage',
            showSort: true,
            checked: true,
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            sortOrder: '',
            nzAlign: 'right',
            dataType: 'PERCENTCOLOR',
        },
        {
            label: 'Total Expense Ratio',
            key: 'totalExpenseRatio',
            showSort: true,
            checked: true,
            nzWidth: '100px',
            nzClass: 'tHeadFont-14',
            sortOrder: '',
            nzAlign: 'right',
            dataType: 'PERCENT',
        },
        {
            label: 'Inception Date',
            key: 'inceptionDate',
            showSort: true,
            checked: true,
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            sortOrder: '',
            nzAlign: 'left',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy'
        },
        {
            label: 'Status',
            key: 'fundStatus',
            showSort: true,
            checked: true,
            nzWidth: '180px',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            sortOrder: '',
            nzAlign: 'left',
        },
    ];

    // cash projection table
    public static readonly CASH_PROJECTION_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Date',
            key: 'trustDate',
            nzWidth: '200px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'trustDate'),
        },
        {
            label: 'Currency',
            key: 'displayCurrency',
            nzWidth: '100px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'displayCurrency'),
        },
        {
            label: 'Purchasing Power',
            key: 'aggregatedEstTotalPurchasingPower',
            nzWidth: '200px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'aggregatedEstTotalPurchasingPower'),
        },
    ];

    // Historical Orders

    public static readonly HISTORICAL_ORDER_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Transaction Date',
            key: 'orderPlacementDate',
            nzWidth: '',
            nzClass: 'tHeadFont-13',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
        },
        {
            label: 'No. of orders',
            key: 'orderNumber',
            nzWidth: '',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
        },
    ];

    public static readonly HISTORICAL_ORDER_INNER_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Order No.',
            key: 'recommendationId',
            nzWidth: '160px',
            nzClass: 'tHeadFont-13',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: false,
            sortOrder: ''
        },
        {
            label: 'Created By',
            key: 'createdBy',
            nzWidth: '150px',
            nzClass: 'tHeadFont-13',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: false,
            sortOrder: ''
        },
        {
            label: 'Approve By',
            key: 'approvedBy',
            nzWidth: '150px',
            nzClass: 'tHeadFont-13',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: false,
            sortOrder: ''
        },
        {
            label: 'Portfolio',
            key: 'portfolioName',
            nzWidth: '300px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px font-semibold',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: ''
        },
        {
            label: 'Estimated Cash',
            key: 'netFlow',
            nzWidth: '200px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortOrder: ''
        },
        {
            label: 'Number of Transactions',
            key: 'totalOrderItems',
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortOrder: '',
            hasTooltip: true,
        },
        {
            label: 'Action',
            key: 'action',
            nzWidth: '200px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'center',
        },
    ];

    public static readonly HISTORICAL_ORDER_DETAIL_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Action',
            key: 'action',
            checked: true,
            nzWidth: '100px',
            nzClass: 'tHeadFont-13',
            nzAlign: 'center',
            nzClassBody: '',
            showSort: false,
            sortOrder: '',
            nzLeft: true
        },
        {
            label: 'Name',
            key: 'productName',
            checked: true,
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px font-semibold',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            nzLeft: true
        },
        {
            label: 'Product Type',
            key: 'productType',
            checked: true,
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: '',
            showSort: true,
            sortOrder: '',
            nzLeft: true
        },
        {
            label: 'Bank Name',
            key: 'bankName',
            checked: true,
            nzWidth: '200px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            showFilter: true,
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Effective Date',
            key: 'effectiveDate',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Maturity Date',
            key: 'maturityDate',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Order Metrics',
            key: 'ordMetrics',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            showFilter: true,
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_UT, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Order Type',
            key: 'orderType',
            checked: true,
            nzWidth: '100px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            showFilter: true,
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Time In Force',
            key: 'timeInForce',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            showSort: true,
            sortOrder: '',
            showFilter: true,
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'GTD',
            key: 'goodTillDate',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Currency',
            key: 'currency',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            showFilter: true
        },
        {
            label: 'Minimum Amount',
            key: 'ordProductMinOrderAmount',
            checked: true,
            nzWidth: '130px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
            dataType: 'NUMBER',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_UT, ProductConstant.PRODUCT_TYPE_ON_DEPO],
        },
        {
            label: 'Price',
            key: 'price',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortOrder: ''
        },
        {
            label: 'Interest Rate',
            key: 'interestRate',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Effective Date (Transfer Date)',
            key: 'transferDate',
            checked: true,
            nzWidth: '180px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            productTypeList: [ProductConstant.PRODUCT_TYPE_ON_DEPO],
        },
        {
            label: 'Qty',
            key: 'quantity',
            checked: true,
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortOrder: ''
        },
        {
            label: 'Accrued Interest',
            key: 'estimatedAccruedInterest',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_BOND],
        },
        {
            label: 'Expected Yield',
            key: 'expectedYield',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_BOND],
        },
        // {
        //     label: 'Filled Qty',
        //     key: 'filledQuantity',
        //     checked: true,
        //     nzWidth: '50px',
        //     nzClass: 'tHeadFont-14',
        //     nzAlign: 'right',
        //     nzClassBody: 'text-13px',
        //     dataType: 'NUMBER',
        //     showSort: true,
        //     sortOrder: ''
        // },
        // {
        //     label: 'Filled Price',
        //     key: 'filledPrice',
        //     checked: true,
        //     nzWidth: '100px',
        //     nzClass: 'tHeadFont-14',
        //     nzAlign: 'right',
        //     nzClassBody: 'text-13px',
        //     dataType: 'DP2',
        //     showSort: true,
        //     sortOrder: ''
        // },
        {
            label: 'Value',
            key: 'productOrderValue',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_ETF, ProductConstant.PRODUCT_TYPE_UT]
        },
        {
            label: 'Value',
            key: 'productOrderValueBase',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortOrder: '',
            isBaseCurrency: true
        },
        {
            label: '% Total Exposure',
            key: 'targetPercent',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'PERCENT4DP',
            showSort: true,
            sortOrder: '',
            isShowZero: true,
        },
        {
            label: 'Order Status',
            key: 'orderStatus',
            checked: true,
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: '',
            showSort: true,
            sortOrder: ''
        }
    ];

    // Approval
    public static readonly ORDER_APPROVAL_LIST_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Order No.',
            key: 'recommendationId',
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: false,
            mobileViewSeq: 2,
        },
        {
            label: 'Portfolio No.',
            key: 'portfolioId',
            nzWidth: '130px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: false,
            mobileViewSeq: 7,
        },
        {
            label: 'Name',
            key: 'portfolioName',
            nzWidth: '180px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px font-semibold',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'portfolioName'),
            mobileViewSeq: 3,
        },
        {
            label: 'Fund House Code',
            key: 'fundHouseCode',
            nzWidth: '130px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'fundHouseCode'),
            mobileViewSeq: 8,
        },
        {
            label: 'Currency',
            key: 'baseCurrency',
            nzWidth: '80px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'baseCurrency'),
            mobileViewSeq: 9,
        },
        {
            label: 'Status',
            key: 'status',
            nzWidth: '110px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'center',
            nzClassBody: 'text-13px',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'status'),
            mobileViewSeq: 10,
        },
        {
            label: 'Pending order(s)',
            key: 'totalOrderItems',
            nzWidth: '100px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'totalOrderItems'),
            mobileViewSeq: 6,
        },
        {
            label: 'Created By',
            key: 'createdBy',
            nzWidth: '150px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: false,
            mobileViewSeq: 5,
        },
        {
            label: 'Created Date',
            key: 'createdDate',
            nzWidth: '170px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy HH:mm a',
            showSort: true,
            sortFn: (a, b) => GeneralService.sortFn(a, b, 'createdDate'),
            mobileViewSeq: 4,
        },
        {
            label: 'Action',
            key: 'action',
            nzWidth: '160px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'center',
            showSort: false,
            mobileViewSeq: 1,
        }
    ];

    // shared
    public static readonly ORDER_DETAIL_TABLE_FILTER_LIST = [
        'ordMetrics','orderType','timeInForce','currency','bankName'
    ];
    public static readonly REJECTED_ORDER_DETAIL_NOT_SHOW_LIST = [
        'orderStatus','orderRejectionReason'
    ];

    // Pending Order
    public static readonly PENDING_ORDER_DETAIL_TABLE_HEADER: CustomiseTableColumn[] = [
        {
            label: 'Action',
            key: 'action',
            checked: true,
            nzWidth: '80px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
            nzLeft: true,
        },
        {
            label: 'Name',
            key: 'productName',
            checked: true,
            nzWidth: '200px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px font-semibold',
            dataType: 'TEXT',
            nzLeft: true,
        },
        {
            label: 'Product Type',
            key: 'productType',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
            nzLeft: true,
        },
        {
            label: 'Order Metrics',
            key: 'ordMetrics',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'left',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            showSort: true,
            sortOrder: '',
            showFilter: true,
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_UT, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Order Type',
            key: 'orderType',
            checked: true,
            nzWidth: '100px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Time in Force',
            key: 'timeInForce',
            checked: true,
            nzWidth: '100px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'GTD',
            key: 'gtd',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_BOND, ProductConstant.PRODUCT_TYPE_ETF],
        },
        {
            label: 'Bank Name',
            key: 'bankName',
            checked: true,
            nzWidth: '200px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Effective Date',
            key: 'effectiveDate',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Maturity Date',
            key: 'maturityDate',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Currency',
            key: 'currency',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
        },
        {
            label: 'Minimum Amount',
            key: 'ordProductMinOrderAmount',
            checked: true,
            nzWidth: '130px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
            productTypeList: [ProductConstant.PRODUCT_TYPE_UT, ProductConstant.PRODUCT_TYPE_ON_DEPO],
        },
        {
            label: 'Price',
            key: 'price',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
        },
        {
            label: 'Interest Rate',
            key: 'interestRate',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            productTypeList: [ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT],
        },
        {
            label: 'Effective Date (Transfer Date)',
            key: 'transferDate',
            checked: true,
            nzWidth: '180px',
            nzAlign: 'center',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DATE',
            dateFormat: 'dd MMM yyyy',
            productTypeList: [ProductConstant.PRODUCT_TYPE_ON_DEPO],
        },
        {
            label: 'Quantity',
            key: 'quantity',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'NUMBER',
        },
        {
            label: 'Accrued Interest',
            key: 'estimatedAccruedInterest',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            productTypeList: [ProductConstant.PRODUCT_TYPE_BOND],
        },
        {
            label: 'Expected Yield',
            key: 'expectedYield',
            checked: true,
            nzWidth: '150px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            productTypeList: [ProductConstant.PRODUCT_TYPE_BOND],
        },
        {
            label: 'Value',
            key: 'productOrderValue',
            checked: true,
            nzWidth: '120px',
            nzClass: 'tHeadFont-14',
            nzAlign: 'right',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            showSort: true,
            sortOrder: '',
            productTypeList: [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_ETF, ProductConstant.PRODUCT_TYPE_UT]
        },
        {
            label: 'Value',
            key: 'productOrderValueBase',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'DP2',
            isBaseCurrency: true,
            hasTooltip: true,
        },
        {
            label: '% Total Exposure',
            key: 'targetPercent',
            checked: true,
            nzWidth: '120px',
            nzAlign: 'right',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'PERCENT4DP',
            isShowZero: true,
        },
        {
            label: 'Order Status',
            key: 'orderStatus',
            checked: true,
            nzWidth: '170px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: '',
        },
        {
            label: 'Rejection Reason',
            key: 'orderRejectionReason',
            checked: true,
            nzWidth: '250px',
            nzAlign: 'left',
            nzClass: 'tHeadFont-14',
            nzClassBody: 'text-13px',
            dataType: 'TEXT',
        },
    ];
    public static readonly PENDING_ORDER_NOT_SORT_LIST = [
        'action','orderRejectionReason'
    ];
}
